var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-list",
    {
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-info px-5",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.voltar()
                    }
                  }
                },
                [_vm._v("Voltar")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { staticClass: "row mt-4" }, [
        _c(
          "div",
          { staticClass: "col-sm-12 col-md-10" },
          [
            _c("f-selectsearch", {
              directives: [
                {
                  name: "authorize",
                  rawName: "v-authorize",
                  value: ["admPerfilEditar", "admPerfilCriar"],
                  expression: "['admPerfilEditar', 'admPerfilCriar']"
                }
              ],
              attrs: {
                label: "Pesquisar usuário para adicionar",
                name: "seacrh",
                cols: 4,
                searchFunction: _vm.pesquisarUsuarioSelect
              },
              on: { select: _vm.addUsuario }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "table-responsive" },
        [
          _c("b-table", {
            attrs: {
              striped: "",
              hover: "",
              small: "",
              items: _vm.items,
              fields: _vm.colunas
            },
            scopedSlots: _vm._u([
              {
                key: "table-busy",
                fn: function() {
                  return [_c("ui-loading")]
                },
                proxy: true
              },
              {
                key: "cell(dataAdd)",
                fn: function(data) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("dataHora")(data.item.dataAdd)) + " "
                    )
                  ]
                }
              },
              {
                key: "cell(status)",
                fn: function(data) {
                  return [
                    _c("UiSwitch", {
                      directives: [
                        {
                          name: "authorize",
                          rawName: "v-authorize:disable",
                          value: ["admPerfilEditar", "admPerfilCriar"],
                          expression: "['admPerfilEditar', 'admPerfilCriar']",
                          arg: "disable"
                        }
                      ],
                      key: _vm.idx,
                      attrs: { labelTrue: "Sim", labelFalse: "Não" },
                      on: {
                        change: function(vl) {
                          _vm.changeStatus(data.item, vl)
                        }
                      },
                      model: {
                        value: data.item.status,
                        callback: function($$v) {
                          _vm.$set(data.item, "status", $$v)
                        },
                        expression: "data.item.status"
                      }
                    })
                  ]
                }
              },
              {
                key: "cell(action)",
                fn: function(data) {
                  return [
                    _c(
                      "b-button-group",
                      { attrs: { size: "sm" } },
                      [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "authorize",
                                rawName: "v-authorize",
                                value: ["admPerfilEditar", "admPerfilCriar"],
                                expression:
                                  "['admPerfilEditar', 'admPerfilCriar']"
                              }
                            ],
                            attrs: {
                              variant: "outline-danger",
                              title: "remover"
                            },
                            on: {
                              click: function() {
                                return _vm.excluir(data.item)
                              }
                            }
                          },
                          [_c("i", { staticClass: "uil uil-trash icon-size" })]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }